import React from "react";
import PropTypes from "prop-types"
import GiveButton from "./give-button";

import "./main.css"

const TagLine = ({ center, message, matching }) => (
  <div id="tag-line" className={ "color-white " + (center ? "center" : "left") }>
    <div>
      <h2>Building a Home.</h2>
      <h2>Transforming a City.</h2>
    </div>
    <p id="message" className="poppins light medium">{ message }</p>
    <GiveButton matching={matching} />
  </div>
)

TagLine.propTypes = {
  center: PropTypes.bool,
  message: PropTypes.string,
  matching: PropTypes.bool
}

TagLine.defaultProps = {
  center: true,
  message: `Invest in Missoula with your gift of any amount.`,
  matching: false
}

export default TagLine
