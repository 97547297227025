import PropTypes from "prop-types"
import React, { Component } from "react"
import { Link } from "gatsby"
import Logo from "./logo";
import GiveButton from "./give-button";

import "./main.css"
class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      menuActive: false,
      menuFullySticky: false,
    }

    this.handleScroll = function () {
      let fbGive = document.getElementById("facebook-give");

      let currentScrollPos = window.pageYOffset;
      if (currentScrollPos > fbGive.clientHeight) {
        this.setState({
          color: true,
          menuFullySticky: true,
        });
      } else {
        this.setState({
          menuFullySticky: false,
        });

        if (!this.props.color) { // if we don't want a color logo at the top, set the state to show this
          this.setState({
            color: false,
          });
        }
      }
    }.bind(this)

    this.toggleMenu = function (element) {
      this.setState({
        menuActive: this.state.menuActive === false,
      })
    }.bind(this)

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    document.getElementById('nav-toggle').addEventListener("click", () => {
      this.toggleMenu(document.getElementById('nav-toggle'));
    });

    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.querySelector("#nav-toggle").removeEventListener("click", () => { })
  }

  render() {
    return (
      <nav id="menu" className={"sweep-to-bottom " + (this.state.menuActive ? "active" : "") + " " + (this.state.menuFullySticky ? "background-white" : "")}>
        <div id="container">
          <Link className="float-left" to="/">
            <Logo color={this.state.color} />
          </Link>
          <div id="nav-toggle" className={(this.state.menuActive ? "active" : "") + " " + (!this.state.menuFullySticky && !this.state.color ? "white" : "")}><span></span></div>
          <div id="links" className="float-right poppins small">
            <Link
              id="the-vision"
              className={this.state.color ? "color-black" : "color-white"}
              activeClassName="active"
              to="/the-vision"
            >
              <b>THE VISION</b>
            </Link>
            <Link
              id="partner"
              className={this.state.color ? "color-black" : "color-white"}
              activeClassName="active"
              to="/partner"
            >
              <b>PARTNER</b>
            </Link>
            <div className="auto-margin">
              <GiveButton text="GIVE" />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

TopMenu.propTypes = {
  color: PropTypes.bool,
}

TopMenu.defaultProps = {
  color: false,
}

export default TopMenu
