import PropTypes from "prop-types"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./main.css"

const Logo = ({ color }) => (
  <div id="logo"
    className={color ? "color": ""}
  >
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo-sprite.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(quality: 100, width: 315, height: 33) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data =>
        <Img
          className={color ? "color": ""}
          fixed={data.logo.childImageSharp.fixed}
          critical={true}
          fadeIn={false}
        />
        }
    />
  </div>
)

Logo.propTypes = {
  color: PropTypes.bool,
}

Logo.defaultProps = {
  color: false,
}

export default Logo
