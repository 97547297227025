import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from 'gatsby-background-image';
import Img from "gatsby-image";
import PartnerBox from "../components/partner-box";

const PartnerPage = ({data}) => (
  <Layout color={true}>
    <SEO title="Partner" keywords={[`missoula`, `sovereign hope`, `church`]} />
    <BackgroundImage
      style={{
        display: `flex`,
        justifyContent: `center`,
      }}
      Tag="section"
      fluid={data.partnerHeader.childImageSharp.fluid}
    >
      <div id="partner-header">
        <Img
          fixed={data.checkMarkIcon.childImageSharp.fixed}
        critical={true}
      />
        <p className="poynter bold ridiculous color-blue no-margin-bottom">Partner with Us</p>
        <p className="poppins">See how you can participate in the campaign.</p>
      </div>
    </BackgroundImage>
    <div id="partner-boxes" className="poppins">
      {
        [{
          video: "https://www.youtube.com/embed/Y9e3hmIkdG4?modestbranding=1&rel=0&iv_load_policy=3",
          title: "Setup a Facebook Fundraiser.",
          body: (<p>It’s easy! Just set a goal and invite friends to donate! Is your birthday coming up? Start a birthday fundraiser for Sovereign Hope Church to raise money for the building.</p>),
          actionBlue: {
            link: "https://www.facebook.com/fund/sovereignhopechurch/",
            text: "Start a Fundraiser",
          },
          actionGreen: {
            link: "https://www.facebook.com/fundraisers?birthday&source=pships",
            text: "Start a Birthday Fundraiser",
          },
        },
        {
          video: "https://www.youtube.com/embed/ZDaumeczQZk?modestbranding=1&rel=0&iv_load_policy=3",
          title: "Do you shop at amazon.com?",
          body: (<p>Shop at <a className="color-green" href="http://smile.amazon.com/ch/81-0460000" target="_blank" rel="noopener noreferrer">smile.amazon.com</a> instead. It's the same Amazon shopping experience but AmazonSmile will donate 0.5% of your eligible purchases to our building fund! Just click the button below or choose Sovereign Hope from a list of nonprofits at <a className="color-green" href="http://smile.amazon.com/ch/81-0460000" target="_blank" rel="noopener noreferrer">smile.amazon.com.</a></p>),
          actionBlue: {
            link: "http://smile.amazon.com/ch/81-0460000",
            text: "Shop on smile.amazon.com",
          },
        },
//        {
//          video: "https://www.youtube.com/embed/0rNh3F1WtrY?modestbranding=1&rel=0&iv_load_policy=3",
//          title: "Help with our t-shirt campaign!",
//          body: (<p>Help us raise funds and represent the community that God has placed us in by picking up one of our “Missoula Good” t-shirts! Tell your friends to visit <a className="color-green" href="http://missoulagood.com" target="_blank" rel="noopener noreferrer">missoulagood.com</a> to pick from one of our three designs.</p>),
//          actionBlue: {
//            link: "http://missoulagood.com",
//            text: "Shop Now",
//          },
//        },
        {
          fixedImage: data.coffee.childImageSharp.fixed,
          title: "Buy Hygge Coffee through this link.",
          body: (<p>Hygge Coffee has created this affiliate link to purchase coffee from. Any coffee purchase made from this link contributes 15% of your total order to A Church Building. Choose from your favorite beans/blends, enjoy ethically sourced coffee, and share the link with your friends!</p>),
          actionBlue: {
            link: "https://hyggecoffeeco.com/?afmc=1c&utm_campaign=1c&utm_source=leaddyno&utm_medium=affiliate",
            text: "Shop Now",
          },
        },
        {
          fixedImage: data.piggyBank.childImageSharp.fixed,
          title: "Save your spare change.",
          body: (<p>Help us collectively raise funds by combining our spare change. Just set aside spare change each week and bring it to Sovereign Hope to add it to our massive piggy bank. This is a great way for your kids to be involved!</p>),
        },
        {
          fixedImage: data.missoulaMarathonLogo.childImageSharp.fixed,
          title: "Run in the Missoula Marathon for our building campaign.",
          body: (<p>Be a part of the Missoula Marathon in 2019 and 2020 to run for a gospel legacy! Share your cause with family, friends, and neighbors so we can partner together in this race towards a new home and a lasting ministry in our community.</p>),
          actionBlue: {
            link: "https://sovhope.churchcenter.com/people/forms/43941",
            text: "Join the Team",
            churchCenter: true,
          },
        },
        {
          fixedImage: data.activitiesAndEvents.childImageSharp.fixed,
          title: "Participate in our fundraising activities and events.",
          body: (<p>Subscribe below and we’ll keep you updated on opportunities to participate.</p>),
          mailChimp: "https://church.us6.list-manage.com/subscribe/post?u=b47a373161df268db440eb37e&amp;id=c820fb7d11",
          actionBlue: {
            link: "https://sovhope.churchcenter.com/people/forms/37988",
            text: "Subscribe",
            churchCenter: true,
          },
        },
        {
          fixedImage: data.volunteer.childImageSharp.fixed,
          title: "Volunteer to help.",
          body: (<p>Want to host a fundraiser, offer a matching fund, or volunteer in some area? We'd love to connect.</p>),
          actionBlue: {
            link: "https://sovhope.churchcenter.com/people/forms/37516",
            text: "Volunteer",
            churchCenter: true,
          },
        },
        {
          fixedImage: data.giftPlusGift.childImageSharp.fixed,
          title: "Ask your employer if they can offer matching gifts.",
          body: (<p>See if your employer will double your donations or even offer a matching fund to our building campaign up to a certain amount.</p>),
        },
        {
          video: "https://www.youtube.com/embed/mpAMmZ7GKH4?modestbranding=1&rel=0&iv_load_policy=3",
          title: "Maximize your donation.",
          body: (<p>When you use ACH (direct donations from your bank account) your donation is saved lots of transaction fees which ultimately end up in more of your donation actually making it to Sovereign Hope. Signing up is easy and free.</p>),
          actionBlue: {
            link: "https://sovhope.churchcenter.com/giving/to/a-church-building-fund",
            text: "Setup ACH Bank Transfers",
            churchCenter: true,
          },
        },
        {
          fixedImage: data.offering.childImageSharp.fixed,
          title: "Make a pledge",
          body: (<p>Will you consider commiting to financially support <i>A Church Building?</i></p>),
          actionBlue: {
            link: "http://sovereignhope.church/pdf/A-Church-Building-giving-guide-web.pdf",
            text: "View the Giving Guide",
          },
          actionGreen: {
            link: "https://sovhope.churchcenter.com/people/forms/41769",
            text: "Make a Pledge",
            churchCenter: true,
          },
        },].map((item, key) => {
          item.title = (key + 1) + ". " + item.title;
          return (
            <PartnerBox
              key={key}
              {...item}
            />
          )
        })
      }
    </div>
    <BackgroundImage
      style={{
        height: `500px`
      }}
      Tag="section"
      fluid={data.tellUsHow.childImageSharp.fluid}
    >
      <div id="tell-us-how">
        <h2 className="color-white">What will your legacy be?</h2>
        <p className="poppins color-white">How will you commit to build a gospel legacy with us in Missoula? Help encourage and inspire others to magnify Christ in our city.</p>
        <a className="button background-blue poppins bold color-white tiny" href="https://sovhope.churchcenter.com/people/forms/37982" data-open-in-church-center-modal="true">Tell Us How</a>
      </div>
    </BackgroundImage>
  </Layout>
)

export const query = graphql`
  query {
    partnerHeader: file(relativePath: { eq: "the-vision-header.png" }) {
      childImageSharp {
        fluid(maxHeight: 486) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    checkMarkIcon: file(relativePath: { eq: "check-mark-icon.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 85, width: 95) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    amazonSmileLogo: file(relativePath: { eq: "amazon-smile-logo.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 62, width: 277) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    ach: file(relativePath: { eq: "ach.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 173, width: 173) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    activitiesAndEvents: file(relativePath: { eq: "activities-and-events.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 88, width: 108) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    gcf: file(relativePath: { eq: "gcf-tshirt.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 224, width: 200) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    giftPlusGift: file(relativePath: { eq: "gift-plus-gift.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 110, width: 253) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    missoulaMarathonLogo: file(relativePath: { eq: "missoula-marathon-logo.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 149, width: 154) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    offering: file(relativePath: { eq: "offering.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 101, width: 130) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    piggyBank: file(relativePath: { eq: "piggy-bank.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 93, width: 87) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    volunteer: file(relativePath: { eq: "volunteer.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 193, width: 234) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
    tellUsHow: file(relativePath: { eq: "tell-us-how.png" }) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    coffee: file(relativePath: { eq: "coffee.png" }) {
      childImageSharp {
        fixed(quality: 100, height: 145, width: 97) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    },
  }
`

export default PartnerPage
