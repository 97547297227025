import React from "react";
import PropTypes from "prop-types"
import Img from "gatsby-image";

import "./main.css"

const PartnerBox = ({ fixedImage, video, title, body, actionBlue, actionGreen, mailChimp }) => (
  <div id="partner-box">
    { fixedImage === undefined ? getVideo(video) : getImage(fixedImage) }
    <div id="text">
      <h4>{title}</h4>
      {body}
    </div>
    {mailChimp.length > 0 ? (getMailChimpForm(mailChimp)) : (<div id="action-items">
      { actionBlue !== undefined ? getActionButton(actionBlue.link, actionBlue.text, actionBlue.churchCenter, "blue") : "" }
      { actionGreen !== undefined ? getActionButton(actionGreen.link, actionGreen.text, actionGreen.churchCenter, "green") : "" }
    </div>)}

  </div>
)

function getImage(fixedImage) {
  return (<div id="image">
    <Img
      fixed={fixedImage}
      critical={true}
    />
  </div>)
}

function getVideo(url) {
  return (<div id="video">
    <div id="wrapper">
      <iframe
        title="video"
        src={url}
        frameBorder="0"
      ></iframe>
    </div>
  </div>)
}

function getActionButton(link, text, churchCenter, color) {
  if (churchCenter !== undefined && churchCenter) {
    return (<div id="button-wrapper"><a
      className={`button background-${color} bold color-white tiny`}
      href={link}
      data-open-in-church-center-modal="true"
    >
      {text}
    </a></div>)
  }

  return (<div id="button-wrapper"><a
    className={`button background-${color} bold color-white tiny ${link === "" ? "no-click" : ""}`}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a></div>)
}

function getMailChimpForm(link) {
  return (<div id="mc_embed_signup">
  <form action={link} method="post" id="mc-embedded-subscribe-form"
    name="mc-embedded-subscribe-form" className="validate"
    target="_blank" noValidate>
    <div id="mc_embed_signup_scroll">
      <div class="mc-group-wrapper">
        <div class="mc-field-group">
          <input placeholder="first name" type="text" name="FNAME" class="" id="mce-FNAME" />
        </div>
        <div class="mc-field-group">
          <input placeholder="last name" type="text" name="LNAME" class="" id="mce-LNAME" />
        </div>
      </div>
      <div className="mc-field-group">
        <input placeholder="enter your email address here" type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
      </div>
      <div id="mce-responses" className="clear">
        <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
        <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
      </div>
      <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
        <input type="text" name="b_b47a373161df268db440eb37e_c820fb7d11" tabIndex="-1" value="" />
      </div>
      <div className="clear">
        <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button background-blue bold tiny color-white" />
      </div>
    </div>
  </form></div>)
}

PartnerBox.propTypes = {
  fixedImage: PropTypes.object,
  video: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  actionBlue: PropTypes.object,
  actionGreen: PropTypes.object,
  mailChimp: PropTypes.string
}

PartnerBox.defaultProps = {
  mailChimp: ""
}

export default PartnerBox
