import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet";

import Header from "./header"
import "./layout.css"
import Footer from "./footer";

const Layout = ({ children, color }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <script src="https://js.churchcenter.com/modal/v1"></script>
        </Helmet>
        <div id="facebook-give" className="background-pink poppins small center">
          <a
            href="https://www.facebook.com/fund/sovereignhopechurch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Build with us! Setup a Facebook fundraiser for our building here.
          </a>
        </div>
        <Header color={color} />
        <div>
          <main>{children}</main>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.bool,
}

Layout.defaultProps = {
  color: false,
}

export default Layout
