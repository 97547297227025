import PropTypes from "prop-types"
import React, { Component } from "react"
import TagLine from "./tag-line";

import "./main.css"
class Footer extends Component {
  render() {
    return (
      <footer>
        <div
          id="main"
          className="background-pink"
        >
          <div
            id="padding"
          >
            <TagLine />
          </div>
        </div>
        <div
          id="copyright"
          className="poppins small center"
        >
          <p className="large no-margin-bottom"><b>© <a href="https://sovereignhope.church/" target="_blank" rel="noopener noreferrer">Sovereign Hope Church</a></b></p>
          <p className="no-margin-bottom">2010 S 3rd St W Suite A, Missoula, MT 59801</p>
          <a href="https://sovhope.churchcenter.com/people/forms/37984" data-open-in-church-center-modal="true">Contact Us</a>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
