import React from "react";
import PropTypes from "prop-types"

import "./main.css"

const GiveButton = ({ text, matching }) => (
  <div id="give-button" className="poppins small">
    <a
      className="background-yellow color-white button"
      href={"https://sovhope.churchcenter.com/giving/to/a-church-building-fund"}
      data-open-in-church-center-modal="true"
    >
      <b>{text}</b>
    </a>
    {!matching ? '' :
      (
        <div id="matching">
          <div id="arrow"></div>
          <div id="body">
            <div id="icon" className="background-pink center float-left">
              <p className="margin-auto">2X</p>
            </div>
            <p className="light" >All donations currently doubled by matching funds!</p>
          </div>
        </div>
      )
    }
  </div>
)

GiveButton.propTypes = {
  text: PropTypes.string,
  matching: PropTypes.bool
}

GiveButton.defaultProps = {
  text: `GIVE NOW`,
  matching: false
}

export default GiveButton
