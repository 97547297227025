import PropTypes from "prop-types"
import React from "react"
import TopMenu from "./top-menu";

import "./main.css"

const Header = ({color}) => (
  <header>
    <TopMenu color={color}></TopMenu>
  </header>
)

Header.propTypes = {
  color: PropTypes.bool,
}

Header.defaultProps = {
  color: false,
}

export default Header
